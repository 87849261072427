<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">Add Role</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="mt-2">

        <ValidationObserver ref="form">

          <form>
            <div class="columns is-tablet">
              <div class="column is-half-tablet">
                <ValidationProvider name="Role" rules="required|email" v-slot="{ errors }">
                  <b-field label="Role" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" id="role_name" v-model="form.role_name"
                             placeholder="Input role name"></b-input>
                  </b-field>
                </ValidationProvider>
                <p class="has-text-weight-bold mb-3 mt-5">Select user permission on each pond</p>

                <p class="is-size-7 mb-5">
                  <span class="has-text-weight-bold">All Access</span>:
                  User has access to View, Insert, Update or Delete the data
                </p>
              </div>
            </div>

            <div class="columns is-tablet">

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('cycle.start') }} {{ $t('and') }} {{ $t('cycle.end_cycle') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('stock.stock') }} {{ $t('and') }} {{ $t('harvest.harvest') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('warehouse.warehouse') }} / {{ $t('warehouse.add_item') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('warehouse.use_item') }} / {{ $t('farm.pond') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('wq.wq') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>{{ $t('feed_tray.feed_tray') }}</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>Sampling</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>Disease</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>Finance</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>Death Count</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="column is-half-tablet">
                <div>
                  <b-field>
                    <b-checkbox>User</b-checkbox>
                  </b-field>
                  <div class="is-divider"></div>
                  <div class="is-flex">
                    <b-button type="is-primary" rounded expanded>{{ $t('user.view_only') }}</b-button>
                    <b-button type="is-primary is-light" class="mx-2" rounded expanded>{{ $t('user.view_insert') }}</b-button>
                    <b-button type="is-primary is-light" rounded expanded>{{ $t('user.all') }}</b-button>
                  </div>
                </div>
              </div>

            </div>

            <div class="has-text-right">
              <b-button type="is-primary is-gradient">{{ $t('save') }}</b-button>
            </div>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddRole",
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      form: {},
      data: [],

      ponds: []
    }
  },
  computed: {
    selectedFarm() {
      return this.$store.getters["farm/selectedFarm"]
    },
  },
  methods: {
    addRole() {
      this.$emit('add-role')
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

