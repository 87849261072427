<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">
            {{ defaultValue ? $t('edit') : $t('add') }} User
          </h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">
        <div class="columns is-tablet">
          <div class="column" :class="{
            'is-half-tablet': form.role && ![1,2,undefined].includes(form.role.value)
          }">
            <ValidationObserver ref="form">
              <form @submit.prevent="handleSubmit">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <b-field label="Email" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" id="email" v-model="form.email"
                             :disabled="defaultValue ? true : false"
                             placeholder="Input email"></b-input>
                  </b-field>
                </ValidationProvider>
<!--                <p class="mb-5">-->
<!--                  <span class="has-text-weight-bold">Hint</span>:-->
<!--                  Has the user registered yet? Enter the user's email and we'll send-->
<!--                </p>-->

                <ValidationProvider name="User Role" rules="required" v-slot="{ errors }">
                  <b-field label="User Role" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <p class="control w-100">
                      <sg-select type="is-secondary is-light" no-outline expanded
                                 :items="roles"
                                 v-model="form.role" :placeholder="`${$t('select')} Role`"></sg-select>
                    </p>
<!--                    <b-dropdown class="ml-2" position="is-bottom-left">-->
<!--                      <b-button type="is-secondary is-light" slot="trigger">-->
<!--                        <b-icon icon="dots-horizontal"></b-icon>-->
<!--                      </b-button>-->
<!--                      <b-dropdown-item @click="addRole">{{ $t('add') }} Role</b-dropdown-item>-->
<!--                      <b-dropdown-item>{{ $t('edit') }} Role</b-dropdown-item>-->
<!--                      <b-dropdown-item>{{ $t('delete') }} Role</b-dropdown-item>-->
<!--                    </b-dropdown>-->
                  </b-field>
                </ValidationProvider>
              </form>
            </ValidationObserver>
          </div>
          <div class="column is-half-tablet" v-if="form.role && ![1,2,undefined].includes(form.role.value)">
            <div v-for="(block, key) in selected" :key="key" class="mb-3">
              <div v-if="block.ponds.length">
                <label class="label">{{ $t('user.permission') }}</label>
                <b-field>
                  <b-checkbox :disabled="!form.role || [1,2,undefined].includes(form.role.value)" v-model="block.value"
                              @input="toggleSelectBlock(key)">
                    Block {{ block.block_name }}
                  </b-checkbox>
                </b-field>

                <div class="is-divider"></div>

                <div class="columns is-tablet">
                  <div class="column is-half-tablet" v-for="(pond,k) in block.ponds" :key="k">
                    <div class="card is-bordered" :class="{
                      'has-background-primary-light has-border-primary': pond.value
                      }">
                      <label class="cursor-pointer">
                        <div class="card-content px-4 py-4 is-flex">
                          <b-field class="mb-0 is-flex">
                            <b-checkbox :disabled="!form.role || [1,undefined].includes(form.role.value)"
                                        v-model="pond.value" @input="toggleSelectPond(key)">{{ pond.name }}
                            </b-checkbox>
                          </b-field>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <p v-else>Block {{ block.block_name }} doesn't have any pond</p>
            </div>
          </div>
        </div>


        <div class="has-text-right">
          <b-button type="is-primary" @click="handleSubmit" class="is-gradient">{{ $t('save') }}</b-button>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "AddUser",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    blocks: {
      type: Array,
    },
    defaultValue: {
      type: Object,
      required: false,
    }
  },
  computed: {
    roles() {
      return this.$store.state.farm.roles.map(e => {
        return {
          value: e.role_id,
          label: e.role_name,
        }
      })
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    }
  },
  data: () => {
    return {
      form: {
        role: null,
      },
      data: [],

      ponds: [],

      selected: [],
    }
  },
  watch: {
    blocks: {
      deep: true,
      handler() {
        this.resetSelectedForm()
      }
    }
  },
  async created() {
    await this.getRole()
    this.resetSelectedForm()
  },
  methods: {
    async getRole() {
      await this.$store.dispatch('farm/getRole')
      if (this.defaultValue) {
        this.form.role = this.roles.find(e => e.value === this.defaultValue.role_id)
      }
    },

    resetSelectedForm() {
      this.form = {
        role: null
      }
      if (this.defaultValue) {
        this.form.email = this.defaultValue.email
        this.form.role = this.roles.find(e => e.value === this.defaultValue.role_id)
      }

      this.selected = []

      let access_pond = this.defaultValue ? this.defaultValue.access_pond.map(e => e.pond_id) : []

      this.selected = this.blocks.map(e => {
        let res = {
          block_id: e.block_id,
          block_name: e.block_name,
          value: true,
          ponds: e.ponds.map(f => {
            return {
              pond_id: f.pond_id,
              name: f.name,
              value: access_pond.includes(f.pond_id),
            }
          }),
        }

        if (res.ponds.find(e => e.value === false)) res.value = false

        return res
      })
    },

    toggleSelectBlock(key) {
      this.selected[key].ponds.map(e => {
        e.value = this.selected[key].value
        return e
      })
    },

    toggleSelectPond(key) {
      let selected = true

      this.selected[key].ponds.forEach(pond => {
        if (!pond.value) {
          selected = false
        }
      })

      this.selected[key].value = selected
    },

    addRole() {
      this.$emit('add-role')
    },
    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()

          let data = {
            farm_id: this.selectedFarm.farm_id,
            role_id: this.form.role.value,
            email: this.form.email,
            access_pond: []
          }

          if (![1, 2].includes(data.role_id)) {
            data.access_pond = this.selected.reduce((a, b) => {
              a = a.concat(
                  b.ponds.filter(e => e.value).map(e => {
                    return {pond_id: e.pond_id}
                  })
              )
              return a
            }, [])
          }

          let res = {
            error: true
          }

          if (this.defaultValue) {
            data.user_farm_id = this.defaultValue.user_farm_id
            data.user_id = this.defaultValue.user_id
            res = await this.$store.dispatch('farm/updateUserFarm', data)
          } else {
            res = await this.$store.dispatch('farm/addUserFarm', data)
          }

          if (!res.error) {
            this.form = {}
            this.$emit('submit')
            this.$emit('close')
          }

          this.$loading(false)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

