var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal}},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.defaultValue ? _vm.$t('edit') : _vm.$t('add'))+" User ")]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1):_vm._e()]),_c('div',[_c('p',{staticClass:"has-text-secondary"},[_vm._v(_vm._s(_vm.selectedFarm.name))])])]),_c('section',{staticClass:"my-2"},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column",class:{
            'is-half-tablet': _vm.form.role && ![1,2,undefined].includes(_vm.form.role.value)
          }},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Email","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"email","disabled":_vm.defaultValue ? true : false,"placeholder":"Input email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"User Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"User Role","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('p',{staticClass:"control w-100"},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.roles,"placeholder":((_vm.$t('select')) + " Role")},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})],1)])]}}])})],1)])],1),(_vm.form.role && ![1,2,undefined].includes(_vm.form.role.value))?_c('div',{staticClass:"column is-half-tablet"},_vm._l((_vm.selected),function(block,key){return _c('div',{key:key,staticClass:"mb-3"},[(block.ponds.length)?_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('user.permission')))]),_c('b-field',[_c('b-checkbox',{attrs:{"disabled":!_vm.form.role || [1,2,undefined].includes(_vm.form.role.value)},on:{"input":function($event){return _vm.toggleSelectBlock(key)}},model:{value:(block.value),callback:function ($$v) {_vm.$set(block, "value", $$v)},expression:"block.value"}},[_vm._v(" Block "+_vm._s(block.block_name)+" ")])],1),_c('div',{staticClass:"is-divider"}),_c('div',{staticClass:"columns is-tablet"},_vm._l((block.ponds),function(pond,k){return _c('div',{key:k,staticClass:"column is-half-tablet"},[_c('div',{staticClass:"card is-bordered",class:{
                      'has-background-primary-light has-border-primary': pond.value
                      }},[_c('label',{staticClass:"cursor-pointer"},[_c('div',{staticClass:"card-content px-4 py-4 is-flex"},[_c('b-field',{staticClass:"mb-0 is-flex"},[_c('b-checkbox',{attrs:{"disabled":!_vm.form.role || [1,undefined].includes(_vm.form.role.value)},on:{"input":function($event){return _vm.toggleSelectPond(key)}},model:{value:(pond.value),callback:function ($$v) {_vm.$set(pond, "value", $$v)},expression:"pond.value"}},[_vm._v(_vm._s(pond.name)+" ")])],1)],1)])])])}),0)],1):_c('p',[_vm._v("Block "+_vm._s(block.block_name)+" doesn't have any pond")])])}),0):_vm._e()]),_c('div',{staticClass:"has-text-right"},[_c('b-button',{staticClass:"is-gradient",attrs:{"type":"is-primary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }