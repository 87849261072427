<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header>
        <div class="is-flex is-justify-content-space-between">
          <div class="is-flex">
            <b-image
                class="profile-image mr-4"
                rounded
                src="/img/assets/profile.jpg"
                alt="Profile"
            />
            <div>
              <h3 class="is-size-5 has-text-weight-bold">{{ user.username }}</h3>
              <p class="has-text-secondary has-text-weight-bold my-1">{{ user.email }}</p>
              <b-tag class="tag-warning-custom">{{ user.role_name }}</b-tag>
            </div>
          </div>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="mt-5" v-if="user.access_pond && user.access_pond.length">
        <p class="has-text-secondary has-text-weight-bold">Managed Pond</p>
        <div class="mt-2">
          <b-tag size="is-medium" class="mr-2 mb-2" v-for="(pond, key) in user.access_pond" :key="key">{{ pond.pond_name }}</b-tag>
        </div>
      </section>
      <footer class="mt-5" v-if="user.role_id !== 1">
        <div class="is-flex">
          <b-button v-if="canEditUser" class="has-text-weight-bold" expanded type="is-danger is-light" @click="deleteUser">Remove</b-button>
          <b-button v-if="canEditUser" class="has-text-weight-bold ml-2" expanded type="is-text" @click="editUser">Edit User</b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: "DetailUser",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
    }
  },
  computed: {
    canEditUser() {
      return this.$store.getters["farm/canEditUser"]
    },
  },
  data: () => {
    return {
      form: {},
      data: [],
    }
  },
  methods: {
    async deleteUser() {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteUserFarm', this.user.user_farm_id)
        this.$loading(false)
        this.$emit('delete-user')
        this.$emit('close')
      })
    },

    editUser() {
      this.$emit('edit-user')
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 550px !important;
  max-width: 100%;
}
</style>
<style lang="scss">
.profile-image img {
  width: 72px !important;
  height: 72px !important;
  object-fit: cover !important;
}
</style>

