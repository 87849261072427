<template>
  <div>
    <header class="mb-3">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <b-input icon="magnify" :placeholder="$t('search')" class="mr-2" v-model="search"/>

        <div v-if="canAddUser">
          <b-button type="is-danger is-gradient" icon-left="plus" class="mr" @click="addUser">
            {{ $t('add') }} User
          </b-button>
        </div>
      </div>
    </header>

    <!--   User List  -->

    <section>
      <b-table :mobile-cards="false" :data="filteredData" bordered>
        <template #empty>
          <p class="has-text-centered">{{ $t('no') }} Data</p>
        </template>

        <b-table-column field="username" label="User Name" v-slot="props">
          {{ props.row.username || '-' }}
        </b-table-column>

        <b-table-column field="email" label="Email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="role_name" label="Role" v-slot="props">
          {{ props.row.role_name }}
        </b-table-column>

        <b-table-column field="action" label="" centered width="120" v-slot="props">
          <b-button type="is-text" class="has-text-primary has-text-weight-bold" @click="selectUser(props.row)">
            Detail
          </b-button>
        </b-table-column>
      </b-table>
    </section>

    <!--  Modal Add User -->

    <b-modal
        v-model="addModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <add-user :default-value="selectedUser" :blocks="blocks" @add-role="addRole" is-modal @submit="getUser"
                  @close="props.close"></add-user>
      </template>
    </b-modal>

    <!--  Modal Add Role -->

    <b-modal
        v-model="addRoleModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <add-role is-modal @close="props.close"></add-role>
      </template>
    </b-modal>

    <!--  Modal Detail User -->

    <b-modal
        v-model="detailModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <detail-user @edit-user="editUser" @delete-user="getUser" :user="selectedUser" is-modal
                     @close="props.close"></detail-user>
      </template>
    </b-modal>

  </div>
</template>

<script>
import AddUser from "@/components/Farm/User/AddUser";
import DetailUser from "@/components/Farm/User/DetailUser";
import AddRole from "@/components/Farm/User/AddRole";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "User",
  components: {AddRole, DetailUser, AddUser},
  data: () => {
    return {
      current: 1,
      addModal: false,
      addRoleModal: false,
      detailModal: false,
      data: [],

      selectedUser: null,
      search: '',
    }
  },
  watch: {
    'selectedFarm.farm_id'() {
      this.getUser()
    }
  },
  computed: {
    canAddUser() {
      return this.$store.getters["farm/canAddUser"]
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    blocks() {
      return this.selectedFarm.blocks.map(e => {
        e.ponds = this.selectedFarm.ponds.filter(f => f.block_id === e.block_id)
        return e
      })
    },
    filteredData() {
      let res = this.data
      if (this.search) res = res.filter(e => e.username.trim().toLowerCase().includes(this.search.trim().toLowerCase()) || e.email.trim().toLowerCase().includes(this.search.trim().toLowerCase()))
      return res
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    addUser() {
      this.selectedUser = null
      this.addModal = true
    },

    async getUser() {
      this.$loading()

      this.data = []

      let res = await this.$store.dispatch('farm/getUser')

      if (!res.error) {
        this.data = res.data
      }
      this.$loading(false)

      this.initGroup()
    },

    addRole() {
      this.addModal = false
      this.addRoleModal = true
    },

    selectUser(user) {
      this.selectedUser = this._.cloneDeep(user)
      this.detailModal = true
    },

    editUser() {
      this.addModal = true
    },

    initGroup () {
      // for (let i = 0; i < this.filteredData.length; i++) {
      //   let data= this.filteredData[i]
      //   this.chatCreateUser(data)
      // }
      // this.chatCreateGroup()
      // this.chatGroupInviteUsers()
    },

    chatCreateUser(payload) {
      let uid = payload.pu_id;
      let name = payload.username;

      let user = new CometChat.User(uid);

      user.setName(name);

      CometChat.createUser(user, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
          .catch(err => {
            console.log(err)
          })
    },
    chatCreateGroup () {
      let GUID = "sgara" + this.selectedFarm.farm_id;
      let groupName = this.selectedFarm.name;
      let groupType = CometChat.GROUP_TYPE.PRIVATE;

      let group = new CometChat.Group(GUID, groupName, groupType);

      CometChat.createGroup(group).then(
          group => {
            console.log("Group created successfully:", group);
          }, error => {
            console.log("Group creation failed with exception:", error);
          }
      );
    },
    chatGroupInviteUsers () {
      let GUID = "sgara" + this.selectedFarm.farm_id;
      let membersList = this.filteredData.filter(e => e.user_id !== 53).map(e => {
        return new CometChat.GroupMember(e.pu_id, e.role_id === 1 ? CometChat.GROUP_MEMBER_SCOPE.ADMIN : CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
      })

      console.log(membersList)

      CometChat.addMembersToGroup(GUID, membersList, []).then(
          response => {
            console.log("response", response);
          }, error => {
            console.log("Something went wrong", error);
          }
      );
    },
  }
}
</script>
